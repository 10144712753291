import { useMutation } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import {
  LoginMutationData,
  LoginMutationVariables,
  LOGIN_MUTATION,
} from 'graphql/mutation/login.mutation';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMeQuery } from '../graphql/query/me.query';
import { LoadingPage } from './loading.page';

export const clearAuth = (): void => {
  localStorage.removeItem('access');
  localStorage.removeItem('refresh');
};

export const setAuth = (access: string, refresh: string): void => {
  localStorage.setItem('access', access);
  localStorage.setItem('refresh', refresh);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
export const withAuth = (
  Component: any,
  roles: string[] = [],
  optional?: boolean
): React.FC => {
  // eslint-disable-next-line react/display-name
  return (props) => {
    const { t } = useTranslation();
    const router = useRouter();
    const [access, setAccess] = useState(false);
    const { loading, data, error } = useMeQuery();
    const [login] = useMutation<LoginMutationData, LoginMutationVariables>(
      LOGIN_MUTATION
    );
    const { accounts } = useMsal();

    const loginUser = async () => {
      // microsoft seems to have added a new key "sid" to the idTokenClaims and it conflicts with
      // UserCreateInput type from our query-api schema
      // TODO: add sid to the UserCreateInput type in the schema
      const { sid, ...withoutSid } = accounts[0].idTokenClaims;

      const result = await login({
        variables: {
          token: withoutSid,
        },
      });
      setAuth(result.data.tokens.access, result.data.tokens.refresh);
    };

    useEffect(() => {
      loginUser()
        .then(() => {
          setAccess(true);
        })
        .catch((err) => console.error(err));
    }, [accounts]);

    useEffect(() => {
      if (roles.length === 0) {
        setAccess(true);
        return;
      }
      setAccess(false);
      if (!error) {
        return;
      }

      localStorage.clear();
      const path = router.asPath || router.pathname;
      localStorage.setItem('redirect', path);
    }, [error]);

    useEffect(() => {
      if (!data || roles.length === 0) {
        setAccess(true);
        return;
      }

      const next =
        roles.map((role) => data.me.roles.includes(role)).filter((p) => p)
          .length > 0;

      setAccess(next);

      if (!next) {
        router
          .push('/')
          .catch((e: Error) => console.error('failed to redirect to /', e));
      }
    }, [data]);

    if (!optional) {
      if (loading) {
        return <LoadingPage message={t('loadingCredentials')} />;
      }

      if (!access) {
        return <LoadingPage message={t('checkingCredentials')} />;
      }
    }

    return <Component me={data && data.me} {...props} />;
  };
};
