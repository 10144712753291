import { useMutation } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import { Alert, Button, Form, Input, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { AuthFooter } from 'components/auth/footer';
import { AuthLayout } from 'components/auth/layout';
import { setAuth } from 'components/with.auth';
import {
  LOGIN_MUTATION,
  LoginMutationData,
  LoginMutationVariables,
} from 'graphql/mutation/login.mutation';
import { NextPage } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useSettingsQuery } from '../../graphql/query/settings.query';
import scss from './index.module.scss';

const Index: NextPage = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [login] = useMutation<LoginMutationData, LoginMutationVariables>(
    LOGIN_MUTATION
  );
  const { data } = useSettingsQuery();
  const { instance } = useMsal();

  const fetchData = async () => {
    await instance.loginRedirect();
  };

  const finish = async (data: LoginMutationVariables) => {
    setLoading(true);
    try {
      // microsoft seems to have added a new key "sid" to the idTokenClaims and it conflicts with
      // UserCreateInput type from our query-api schema
      // TODO: add sid to the UserCreateInput type in the schema
      const { sid, ...withoutSid } = data as any;
      const result = await login({
        variables: withoutSid,
      });

      setAuth(result.data.tokens.access, result.data.tokens.refresh);

      await message.success(t('login:welcomeBack'));

      await router.push('/admin');
    } catch (e) {
      await message.error(t('login:invalidLoginCredentials'));
    }

    setLoading(false);
  };

  const failed = async () => {
    await message.error(t('validation:mandatoryFieldsMissing'));
  };

  return (
    <AuthLayout loading={loading}>
      <Form
        form={form}
        name='login'
        onFinish={finish}
        onFinishFailed={failed}
        style={{
          margin: 'auto',
          maxWidth: '95%',
          width: 400,
        }}
      >
        <div
          style={{
            display: 'block',
            width: '70%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 35,
          }}
        >
          <img
            style={{ maxWidth: '100%' }}
            src={require('../../assets/images/logo_white.png')}
            alt={'DeloitteDigital'}
          />
        </div>

        {data && data.loginNote.value && (
          <Alert
            type='warning'
            showIcon
            message={t('login:note')}
            description={<ReactMarkdown>{data.loginNote.value}</ReactMarkdown>}
            style={{
              marginBottom: 24,
            }}
          />
        )}

        <Form.Item
          name='username'
          rules={[
            { required: true, message: t('validation:usernameRequired') },
            {
              message: 'not a super user!',
              validator: (_, value) => {
                if (value === 'admin' || value === 'Ye4ncBPVy') {
                  return Promise.resolve();
                } else {
                  return Promise.reject('not a super user!');
                }
              },
            },
          ]}
        >
          <Input
            size='large'
            className='success'
            placeholder={t('login:usernamePlaceholder')}
          />
        </Form.Item>

        <Form.Item
          name='password'
          rules={[
            { required: true, message: t('validation:passwordRequired') },
          ]}
        >
          <Input.Password
            size='large'
            className='success'
            placeholder={t('login:passwordPlaceholder')}
          />
        </Form.Item>

        <Form.Item>
          <Button size='large' type='primary' htmlType='submit' block>
            {t('login:loginNow')}
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            size='large'
            type='default'
            htmlType='button'
            onClick={async () => fetchData()}
            block
          >
            Log in with SSO
          </Button>
        </Form.Item>

        {/* <Button.Group className={scss.otherActions}>
          {(!data || !data.disabledSignUp.value) && (
            <Link href={'/register'}>
              <Button
                type={'link'}
                ghost
                style={{
                  color: '#FFF',
                }}
              >
                {t('register')}
              </Button>
            </Link>
          )}
          <Link href={'/login/recover'}>
            <Button
              type={'link'}
              ghost
              style={{
                color: '#FFF',
              }}
            >
              {t('recover')}
            </Button>
          </Link>
        </Button.Group> */}
      </Form>

      {/* <AuthFooter /> */}
    </AuthLayout>
  );
};

export default Index;
