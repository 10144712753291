import { gql } from '@apollo/client/core';

export interface LoginMutationData {
  tokens: {
    access: string;
    refresh: string;
  };
}

export interface LoginMutationVariables {
  token?: any;
  password?: string;
}

export const LOGIN_MUTATION = gql`
  mutation authLogin($token: UserCreateInput, $password: String) {
    tokens: authLogin(token: $token, password: $password) {
      access: accessToken
      refresh: refreshToken
    }
  }
`;
